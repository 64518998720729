<template>
    <div class="contact">
      <div class="caption">
        <h1 class="title" ref="title">Don’t be a stranger</h1>
        <p ref="mail">
            Write me
            <a href="mailto:pilicorp@gmail.com">pilicorp@gmail.com</a>
        </p>
        <p ref="phone">
            Call me
            <a href="tel:0033618364703">+33 6 18 36 47 03</a>
        </p>
      </div>
    </div>
</template>

<script>
import Splitting from "splitting";
import Metas from "@/utils/metas";
import introduce from "@/views/contact/introduction";
import outroduce from "@/views/contact/outroduction";

export default {
  name: "Contact",
  data() {
    return {
      chars: {
        title: []
      }
    }
  },
  methods: {
    introduce(done) {
      this.initChars();
      const { mail, phone } = this.$refs;
      const chars = this.chars;
      introduce({ chars, mail, phone }, done);
    },
    outroduce(done) {
      const { mail, phone } = this.$refs;
      const chars = this.chars;
      outroduce({ chars, mail, phone }, done);
    },
    initChars() {
      const { title } = this.$refs;
      if(!this.chars.title.length) {
        this.chars.title = Splitting({ target: title })[0].chars;
      }
    }
  },
  metaInfo:  Metas.contact
};
</script>

<style scoped lang="stylus">
  @import "~@/styles/core"
  @import "Contact"
</style>

