import gsap from "gsap";

export default (works, complete) => {
  gsap.timeline()
    .clear()
    .from(
      works,
      {
        y: "10%",
        opacity: 0,
        duration: 1,
        ease: "power2.out",
        stagger: { amount: 0.4, from: "left" }
      },
      0
    )
    .set(works, { clearProps: "all" })
    .call(complete);

}