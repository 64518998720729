import gsap from "gsap";

export default ({chars, mail, phone}, complete) => {
  gsap.timeline()
    .clear()
    .to(
      chars.title,
      {
        y: "-25%",
        opacity: 0,
        textShadow: "0 0 20px rgb(255,255,255)",
        duration: 0.4,
        ease: "power2.in",
        stagger: { amount: 0.2, from: "left" }
      },
      0
    )
    .to(
      mail,
      { y: "-25%", opacity: 0, duration: 0.4, ease: "power2.in" },
      0.1
    )
    .to(
      phone,
      { y: "-25%", opacity: 0, duration: 0.4, ease: "power2.in" },
      0.2
    )
    .call(complete);

}