<template>
  <ul class="works">
    <li class="work"
        ref="works"
        v-for="work in worksList" 
        :key="work.title"
      >
      <a class="work-link" target="_blank" :href="work.url">
        <small class="work-label">{{ work.label }}</small>
        <h2 class="work-title">{{ work.title }}</h2>
        <span class="light" ref="lights" :style="spotlight(work.color)" />
      </a>
    </li>
  </ul>
</template>

<script>
import { Color } from "three";
import { lerp } from "@/utils/animation";
import { hypotenuse } from "@/utils/maths";
import Metas from "@/utils/metas";

import introduce from "@/views/works/introduction";
import outroduce from "@/views/works/outroduction";

const EASE_TIME = 0.05;

const LIGHT_TRANSLATE_FACTOR = 100;
const LIGHT_OPACITY = 0.25;

export default {
  name: "Works",
  data() {
    return {
      time: null,
      mouse: null,
      lights: [],
      works: []
    };
  },
  computed: {
    background() {
      return this.$store.getters.background;
    },
    worksList() {
      return this.$store.getters.works;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeWorks();
    })
  },
  beforeDestroy () {
    cancelAnimationFrame(this.time);
    this.background.resetLight();
  },
  methods: {
    introduce(done) {
      const onComplete = () => {
        done();
        this.move();
      };
      introduce(this.$refs.works, onComplete);
    },
    outroduce(done) {
      outroduce(this.$refs.works, done);
    },
    spotlight(color) {
      return {
        backgroundImage: `radial-gradient(circle closest-side, ${color} 0%, transparent 100%`
      }
    },
    move() {
      this.works.forEach(work => work.update());
      const closest = this.works.reduce((closest, work) => work.distance < closest.distance ? work : closest, this.works[0]);
      this.background.setLight(closest.color);
      this.time = requestAnimationFrame(this.move.bind(this));
    },
    initializeWorks() {
      const _this = this;
      this.works = this.$refs.works.map((work, index) => ({
        $light: this.$refs.lights[index],
        color: new Color(this.worksList[index].color),
        distance: 0,
        origin: {
          x: work.getBoundingClientRect().left + Math.floor(work.offsetWidth/2),
          y: work.getBoundingClientRect().top + Math.floor(work.offsetHeight/2)
        },
        transform: {  rX: 0, rY: 0, tZ: 0 },
        light: { alpha: 0, tX: 0, tY: 0 },
        update: function() {
          const mousePosition = _this.getMousePosition(this.origin);
          const params = {
            x: (mousePosition.y / work.offsetHeight / 2).toFixed(2),
            y: (mousePosition.x / work.offsetWidth / 2).toFixed(2),
            distance: hypotenuse(mousePosition.x, mousePosition.y)
          }

          this.distance = params.distance;

          this.transform = {
            rX: lerp(this.transform.rX, params.x, EASE_TIME),
            rY: lerp(this.transform.rY, params.y, EASE_TIME),
            tZ: lerp(this.transform.tZ, this.distance/200, EASE_TIME)
          };

          work.style.transform = `rotateX(${this.transform.rX}deg) rotateY(${this.transform.rY}deg) translateZ(${this.transform.tZ}px)`;

          const opacityDelta = Math.min(Math.max(this.distance/4000,0), LIGHT_OPACITY);

          this.light = {
              alpha: lerp(this.light.alpha, LIGHT_OPACITY - opacityDelta, EASE_TIME),
               tX: lerp(this.light.tX, params.y * LIGHT_TRANSLATE_FACTOR, EASE_TIME),
               tY: lerp(this.light.tY, params.x * LIGHT_TRANSLATE_FACTOR * -1, EASE_TIME)
          };
          this.$light.style.opacity = `${this.light.alpha}`;
          this.$light.style.transform = `translateX(${this.light.tX}px) translateY(${this.light.tY}px)`;

          this.distance = params.distance;
        }
      }));
    },
    getMousePosition(origin) {
      return {
        x: this.background.u_mouse.x - origin.x,
        y: (this.background.u_mouse.y - origin.y) * -1
      }
    }
  },
  metaInfo: Metas.works
};
</script>

<style lang="stylus" scoped>
  @import "~@/styles/core.styl"
  @import "works.styl"
</style>
