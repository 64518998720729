import gsap from "gsap";

export default (works, complete) => {
  gsap.timeline()
    .clear()
    .to(
      works,
      {
        y: "-10%",
        opacity: 0,
        duration: 0.5,
        ease: "power2.in",
        stagger: { amount: 0.2, from: "left" }
      },
      0
    )
    .call(complete);

}