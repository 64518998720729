import gsap from "gsap";

export default ({chars, mail, phone}, complete) => {
  gsap.timeline()
    .clear()
    .from(
      chars.title,
      {
        y: "25%",
        opacity: 0,
        textShadow: "0 0 20px rgb(255,255,255)",
        duration: 1,
        ease: "power2.out",
        stagger: { amount: 0.4, from: "left" }
      },
      0
    )
    .from(
      mail,
      { y: "25%", opacity: 0, duration: 1, ease: "power2.out" },
      0.35
    )
    .from(
      phone,
      { y: "25%", opacity: 0, duration: 1, ease: "power2.out" },
      0.45
    )
    .set(chars.title, { clearProps: "all" })
    .call(complete);

}